import React, {useEffect} from 'react';
import Masthead from '../components/masthead';
import Copy from '../components/career-copy';
import Slider from '../components/career-slider';
import Form from '../components/services/form';

const CareersTemplate = ({ copy, path }) => {
	const { masthead, columns, slider, form } = copy;
	useEffect(()=>{
		const slides = document.getElementsByClassName("slider-slide");
		Array.from(slides).forEach(slide=> {slide.setAttribute("role", "button")})
	},[]);
	return (
		<>
			<Masthead title={masthead.title} img={masthead.img} />
			<Copy
				title={columns.title}
				left={columns.left}
				right={columns.right}
				cta={columns.cta}
			/>
			<Slider title={slider.title} list={slider.list} img={slider.img} />
			<Form title={form.title} phone={form.phone} img={form.img} path={path} />
		</>
	);
};

export default CareersTemplate;
