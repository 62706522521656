import React from 'react';
import styled from 'styled-components';

import convertBold from '../util/convertBold';
import mdToHtml from '../util/mdToHtml';

const StyledCopyColumns = styled.div`
	padding: 4rem ${(p) => p.theme.gutter};
	text-align: center;
	color: ${(p) => p.theme.color.grey};

	h2 {
		text-align: center;
		margin-bottom: 2rem;
	}

	h3 {
		font-size: 1rem;
		color: ${(p) => p.theme.color.grey};
		margin: 0.5em 0;
	}

	.copy-row {
		display: flex;
		text-align: left;
		margin-bottom: 2rem;
		.left {
			width: 50%;
			padding-right: 2rem;

			.bold {
				font-weight: 700;
				padding: 0.5rem 0;
				line-height: 1.5rem;
			}

			li {
				margin-bottom: 0.75em;
				padding-left: 1em;
				position: relative;

				&::before {
					content: '+';
					position: absolute;
					left: 0;
				}
				p {
					line-height: 1.3rem;
					position: relative;
					top: -20px;
					margin-bottom: -20px;
					margin-left: 8px;
				}
			}
		}
		.right {
			width: 50%;
			padding-left: 2rem;
			.text-inner {
				margin-bottom: 2rem;
			}

			p {
				white-space: pre-wrap;
				a {
					color: ${(p) => p.theme.color.purple};
					position: relative;
					&::after {
						content: '';
						background-color: ${(p) => p.theme.color.purple};
						transition: 0.5s;
						width: 100%;
						height: 1px;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						opacity: 1;
					}
					&:hover::after {
						opacity: 0;
					}
				}
			}

			.redButton {
				text-transform: uppercase;
			}
		}
	}

	@media all and (min-width: 1200px) {
		padding: 4rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 6rem ${(p) => p.theme.gutterXWide};
		.copy-row .left li p {
			top: -27px;
			line-height: 2rem;
		}
	}
	@media all and (max-width: 668px) {
		h2 {
			text-align: left;
		}
		.copy-row {
			flex-wrap: wrap;
			.left {
				width: 100%;
				padding: 0 0 1rem;
			}
			.right {
				width: 100%;
				padding: 0;
			}
		}
	}
	@media all and (max-width: 540px) {
		h2 {
			width: 270px;
			margin: 0 0 2rem;
		}
	}
	@media all and (max-width: 500px) {
		padding: 3rem ${(p) => p.theme.gutterMobile};
	}
	@media all and (max-width: 360px) {
		h2 {
			width: 100%;
		}
	}
`;

const CopyColumns = ({ title, left, right, cta }) => {
	return (
		<StyledCopyColumns>
			<h2
				dangerouslySetInnerHTML={{
					__html: convertBold(title),
				}}
			/>
			<div className="copy-row">
				<div
					className="left"
					dangerouslySetInnerHTML={{ __html: mdToHtml(left) }}
				/>
				<div className="right">
					<div
						className="text-inner"
						dangerouslySetInnerHTML={{ __html: mdToHtml(right) }}
					/>
					<a className="redButton" href={cta.link}>
						{cta.text}
					</a>
				</div>
			</div>
		</StyledCopyColumns>
	);
};

export default CopyColumns;
