import React from 'react';
import styled from 'styled-components';
import convertBold from '../util/convertBold';
import Carousel from 'nuka-carousel';

const StyledServices = styled.div`
	.slider-list {
		cursor: auto;
	}
	display: flex;
	background-color: ${(p) => p.theme.color.purple};
	.left {
		width: 50%;
		padding: 3rem ${(p) => p.theme.gutter};
		color: #fff;
		h2,
		strong {
			color: #fff;
			margin-bottom: 1rem;
		}
		p {
			white-space: pre-wrap;
			line-height: 1.6rem;
		}
		.slider {
			padding: 0 10px;
			.slider-list {
				cursor: auto;
			}
			.testimonial-text {
				margin-bottom: 1em;
			}
		}
		.next,
		.prev {
			position: relative;
			cursor: pointer;
			&::after {
				content: '';
				position: absolute;
				width: 15px;
				height: 15px;
				display: block;
				border-left: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}
		}
		.prev::after {
			right: 10px;
			transform: rotate(45deg);
		}
		.next::after {
			transform: rotate(225deg);
			left: 10px;
		}
	}
	.right {
		background-image: url(${(p) => p.img});
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 50%;
	}
	@media all and (min-width: 1200px) {
		.left {
			padding: 5rem ${(p) => p.theme.gutterWide};
		}
	}
	@media all and (min-width: 1600px) {
		.left {
			padding: 6rem ${(p) => p.theme.gutterXWide};
		}
	}
	@media all and (max-width: 768px) {
		flex-wrap: wrap;
		.left {
			width: 100%;
			padding: 2.5rem ${(p) => p.theme.gutter};
		}
		.right {
			width: 100%;
			height: 21.5rem;
			background-size: auto 300px;
			background-color: ${(p) => p.theme.color.purple};
			background-position: center top;
		}
	}
	@media all and (max-width: 500px) {
		.left {
			padding: 2.5rem ${(p) => p.theme.gutterMobile};
			.prev::after {
				right: 0px;
			}
			.next::after {
				left: 0px;
			}
		}
	}
	@media all and (max-width: 450px) {
		.right {
			height: 20rem;
		}
	}
	@media all and (max-width: 380px) {
		.right {
			background-position: center bottom;
			height: 18rem;
		}
	}

`;

const Services = ({ title, list, img }) => {
	return (
		<StyledServices img={img}>
			<div className="left">
				<h2
					dangerouslySetInnerHTML={{
						__html: convertBold(title),
					}}
				/>
				<Carousel
					wrapAround={true}
					autoplay={true}
					renderCenterLeftControls={({ previousSlide }) => (
						<span className="prev" onClick={previousSlide} role="button" aria-label="previous"/>
					)}
					renderCenterRightControls={({ nextSlide }) => (
						<span className="next" onClick={nextSlide} role="button" aria-label="next"/>
					)}
					renderBottomCenterControls={null}

				>
					{list.map(({ text, name }, index) => (
						<div key={index}>
							<p className="testimonial-text">{text}</p>
							<p>- {name}</p>
						</div>
					))}
				</Carousel>
			</div>
			<div className="right" role="img" aria-label="NAFG team picture" />
		</StyledServices>
	);
};

export default Services;
