import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import CareersTemplate from '../templates/careers-template';

const Careers = ({ data, location }) => {
	const content = data.pagesJson;
	const path = location.pathname;
	return (
		<Layout>
			<SEO title="Careers" description={content.description} />
			<CareersTemplate copy={content} path={path} />
		</Layout>
	);
};

export default Careers;

export const pageQuery = graphql`
	query CareersPageQuery {
		pagesJson(page: { eq: "careers" }) {
			description
			masthead {
				title
				img
			}
			columns {
				title
				left
				right
				cta {
					link
					text
				}
			}
			slider {
				title
				list {
					text
					name
				}
				img
			}
			form {
				phone {
					format
					raw
				}
				title
				img
			}
		}
	}
`;
